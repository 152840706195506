import { Injectable } from '@angular/core';
import { TeamModel } from 'src/app/models/team';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends DataService<TeamModel> {
  endpoint = 'teams';
  objectName = 'team';
}
