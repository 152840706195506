import { Component, OnInit } from "@angular/core";
import { DataprivacyModel } from "src/app/models/dataprivacy";
import { DataprivacyService } from "src/app/provider/model-services/dataprivacy-service";

@Component({
  selector: "app-dataprivacy",
  templateUrl: "./dataprivacy.component.html",
  styleUrls: ["./dataprivacy.component.scss"],
})
export class DataprivacyComponent implements OnInit {
  dataprivicies: DataprivacyModel[] = [];
  constructor(private dataprivService: DataprivacyService) {}

  ngOnInit(): void {
    this.dataprivService.all().subscribe((res) => {
      this.dataprivicies = res;
    });
  }
}
