import { Component, OnInit } from "@angular/core";
import { ImprintModel } from "src/app/models/imprint";
import { ImprintService } from "src/app/provider/model-services/imprint-service";

@Component({
  selector: "app-imprint",
  templateUrl: "./imprint.component.html",
  styleUrls: ["./imprint.component.scss"],
})
export class ImprintComponent implements OnInit {
  imprints: ImprintModel[] = [];

  constructor(public imprintService: ImprintService) {}

  ngOnInit(): void {
    this.imprintService.all().subscribe((res) => {
      this.imprints = res;
    });
  }
}
