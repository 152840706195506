import { TourModel } from './../../models/tour';
import { TourService } from './../../provider/model-services/tour-service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hamburgerOpened: boolean = false;
  tours: TourModel[] = [];
  subpageOpened: boolean[] = [false, false];

  constructor(private router: Router, private tourService: TourService) {
    this.loadTours();
  }

  ngOnInit(): void {}

  openTour(id: number) {
    this.router.navigate(['/']).then(() => {
      this.router.navigate(['/tour-details/' + id], {});
    });
  }

  toggleSubpage(index: number) {
    this.subpageOpened[index] = !this.subpageOpened[index];
  }

  toggleHamburger() {
    this.hamburgerOpened = !this.hamburgerOpened;
  }

  /**
   * Lädt alle Touren und filtert diese direkt
   */
  loadTours() {
    this.tourService.all().subscribe((res) => {
      this.tours = res;
    });
  }
}
