<app-header></app-header>
<section class="tour-infomation-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <aside>
          <div class="tour-infomation__content">
            <div class="tour-infomation__content__header">
              <h2>Gutschein</h2>
            </div>

            <div class="tour-infomation__content__descript">
              <h2></h2>
              <p>
                Erfreuen Sie Ihre Mitmenschen mit einer kleinen Vorfreude auf
                den nächsten Urlaub. Verschenken Sie eine Führung in einer der eindruckvollsten Naturreservate, die
                Deutschland zu bieten hat.
                Gesund in der Natur, immer eine frische Brise voraus und dabei
                mal die Alltagssorgen wegpusten lassen. Ideal dafür ist unsere
                neue originelle Geschenkmuschel für Wattführungen. Sie können
                sie ab sofort bei uns bestellen. Suchen Sie sich eine schöne
                Tour heraus und legen den gewünschten Betrag fest. Wir bitten um
                Verständnis, dass wir für unsere Unikate, die handbemalt wurden,
                <br />
                <strong>Pfand: von 10,- € und Verpackung / Versand: 5,50 € erheben
                  müssen.</strong>
              </p>
            </div>

            <div class="gallery-syncing-item">
              <div class="gallery-syncing-item__single">
                <div class="gallery-syncing-item__single__item">
                  <img src="./../../../assets/images/gutschein-muschel.jpg" alt="gall-syn1" />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>

      <!-- sidebar -->
      <div class="col-lg-4">
        <div class="sidebar">
          <div class="right-sidebar">
            <div class="right-sidebar__item">
              <h3 style="height: 100%">Bestellen</h3>
              <div class="right-sidebar__item__form">
                <label>Gutscheinwert €</label>
                <input type="number" [(ngModel)]="coupon.value" (change)="changedValue()" />
                <label>Preis €</label>
                <input type="number" [(ngModel)]="coupon.price" disabled />
                <label>Nachname</label>
                <input type="text" [(ngModel)]="coupon.lastname" />
                <label>Vorname</label>
                <input type="text" [(ngModel)]="coupon.firstname" />
                <label>Straße, Nr.</label>
                <input type="text" [(ngModel)]="coupon.street" />
                <label>PLZ</label>
                <input type="tel" [(ngModel)]="coupon.zip" />
                <label>Ort</label>
                <input type="text" [(ngModel)]="coupon.city" />
                <label>E-Mail</label>
                <input type="text" [(ngModel)]="coupon.email" />
                <label>Mobilnummer</label>
                <input type="tel" [(ngModel)]="coupon.mobile_phone" />
                <span class="checkboxesOverText">Allgemeine Geschäftsbedingungen | Wiederrufsbelehrung |
                  Datenschutzerklärung *</span>
                <mat-checkbox [(ngModel)]="accepted_first">Mit absenden der Buchungsanfrage akzeptieren Sie unsere
                  Allgemeinen Geschäftsbedingungen sowie die
                  Widerrufsbelehrung.</mat-checkbox>
                <mat-checkbox [(ngModel)]="accepted_second">Ich habe die Datenschutzerklärung gelesen und akzeptiert.
                </mat-checkbox>

                <input type="submit" value="Buchen" [disabled]="!isValid()" (click)="buyCoupon()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-tour-list-horizontal></app-tour-list-horizontal>
  </div>
</section>

<app-footer></app-footer>