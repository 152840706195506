import { Injectable } from "@angular/core";
import { AgbModel } from "src/app/models/agb";
import { DataService } from "../data-service";

@Injectable({
  providedIn: "root",
})
export class AgbService extends DataService<AgbModel> {
  endpoint = "agbs";
  objectName = "agb";
}
