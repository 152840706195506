<div class="container">
  <div class="top-desti__tittle">
    <div class="section-tittle">
      <h2>KENNEN LERNEN</h2>
      <div class="section-tittle__line-under"></div>
      <p><span>unser Team</span></p>
    </div>
  </div>

  <div class="row trending-tour-row">
    <div class="col-lg-4 col-md-6 col-xl-3 col-sm-6 col-12" *ngFor="let team of teams">
      <a class="trending-tour-item">
        <img class="trending-tour-item__thumnail"
          src="{{'assets/images/team/' + team?.id + '.jpg'}}" alt="tour1" />
        <div class="trending-tour-item__info">
          <h3 class="trending-tour-item__name">{{team?.name}}</h3>
          <div class="trending-tour-item__group-infor">
            <div class="trending-tour-item__group-infor--left">
              <div class="trending-tour-item__group-infor__lasting">
                {{ team.description }}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
