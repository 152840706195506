import { GlobalDataService } from "./helper/global-data";
import { CouponService } from "./model-services/coupon-service";
import { ContactService } from "./model-services/contact-service";
import { BookingService } from "./model-services/booking-service";
import { TeamService } from "./model-services/team-service";
import { ScrollHelper } from "./helper/scroll-helper";
import { PartnerService } from "./model-services/partner-service";
import { TourService } from "./model-services/tour-service";
import { DataService } from "./data-service";
import { NgModule } from "@angular/core";

/**
 * Alle Basis-Funktionalität-Provider
 */
const basic = [DataService];
/**
 * Alle Helper
 */
const helpers = [ScrollHelper];
/**
 * Alle Services welche zum größten Teil für die Schnittstelle zum Backend zuständig sind
 */
const services = [
  TourService,
  PartnerService,
  TeamService,
  ContactService,
  CouponService,
  GlobalDataService,
];

@NgModule({
  providers: [basic, helpers, services],
})
export class ProvidersModule {}
