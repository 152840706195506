import { CouponModel } from './../../models/coupon';
import { Injectable } from '@angular/core';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class CouponService extends DataService<CouponModel> {
  endpoint = 'coupons';
  objectName = 'coupon';
}
