import { Component, OnInit } from "@angular/core";
import { AgbModel } from "src/app/models/agb";
import { AgbService } from "src/app/provider/model-services/agb-service";

@Component({
  selector: "app-agb",
  templateUrl: "./agb.component.html",
  styleUrls: ["./agb.component.scss"],
})
export class AgbComponent implements OnInit {
  agbs: AgbModel[] = [];
  constructor(private agbService: AgbService) {}

  ngOnInit(): void {
    this.agbService.all().subscribe((res) => {
      this.agbs = res;
    });
  }
}
