<app-header></app-header>

<section>
  <div class="page-banner banner-background">
    <div class="container">
      <div class="page-banner__tittle">
        <h1>Kontakt</h1>
        <p><span></span></p>
      </div>
    </div>
  </div>
</section>

<section class="contact-infomation">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="contact-infomation__item contact-infomation__form">
          <div class="contact-infomation__info">
            <form class="contact-form" [formGroup]="contactForm" (submit)="sendEmail($event)">
              <label>Ihr Name</label>
              <input type="text" [(ngModel)]="contact.name" formControlName="user_name" />
              <label>Ihre Email-Adresse</label>
              <input type="email" [(ngModel)]="contact.description" formControlName="user_email" />
              <label>Nachricht</label>
              <textarea [(ngModel)]="contact.body" formControlName="message"></textarea>
              <mat-checkbox formControlName="checkbox">Hiermit erkläre ich mich einverstanden, dass meine in das
                Kontaktformular eingegebenen Daten elektronisch gespeichert und
                zum Zweck der Kontaktaufnahme verarbeitet und genutzt werden.
                Die Einwilligung kann jederzeit gemäß Art. 7 Abs. 3 DSGVO durch
                formlose Mitteilung (z.B. per E- Mail) widerrufen
                werden.</mat-checkbox>
              <button *ngIf="contactForm" mat-flat-button color="warn" [disabled]="contactForm.invalid" type="submit">
                Abschicken
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-6">
        <div class="contact-infomation__item contact-infomation__item--padding">
          <div class="contact-infomation__info">
            <p>
              Gönn dir Watt richtet sich an Gäste, die einen perfekten Tag im
              Cuxhavener Wattenmeer verbringen möchten. Wir übernehmen die
              Planung bereits weit vor Beginn der Cuxhaven Wattwanderung und
              erst nach Rückkehr in Ihrem Urlaubsquartier endet unser Engagement
              für Sie. Die Tour wird von uns ganzheitlich zusammengestellt, wir
              betrachten es als unsere Aufgabe, Ihren schönsten Urlaubstag
              auszurichten. Eine detaillierte Vorbereitung, die für Sie
              getroffenen Reservierungen und eine bevorzugte Betreuung werden
              Ihnen lästige Wartezeiten, Anstehen und weitere Unannehmlichkeiten
              ersparen. Genießen Sie die Weite des Weltnaturerbes Wattenmeer und
              seine einzigartigen Besonderheiten und lassen Sie an unserem
              gemeinsamen Tag die Sorgen mal am Strand. Wattführungen in
              Cuxhaven oder eine Wattwanderung nach Neuwerk, eine Wohltat für
              Körper und Seele, komplett durchgeplant durch die
              Wohlfühlspezialisten in Cuxhaven-Duhnen.
            </p>
            <div class="contact-infomation__info__address">
              <div class="contact-infomation__info__address-item">
                <img src="assets/images/contact-phone.png" alt="contact-addresst" />
                <span><a href="tel:+4947572224694">+4947572224694</a> oder
                  <a href="tel:+4915732957797">+4915732957797</a></span>
              </div>
              <div class="contact-infomation__info__address-item">
                <img src="assets/images/contact-mail.png" alt="contact-mail" />
                <span>info@goenndirwatt.de</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
