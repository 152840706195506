<app-header></app-header>
<div class="container" style="margin-top: 5%; margin: 10px">
  <p>
    AGB erstellt unter Zuhilfenahme des Generators der Deutschen Anwaltshotline
    AG. Änderungen und Ergänzungen wurden vorgenommen.
  </p>
  <div *ngFor="let abg of agbs">
    <h2>
      {{ abg.title }}
    </h2>

    <p [innerHTML]="abg.body.body"></p>
  </div>
</div>
<app-footer></app-footer>
