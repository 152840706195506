<app-header></app-header>

<section>
  <div class="page-banner banner-background">
    <div class="container">
      <div class="page-banner__tittle"></div>
    </div>
  </div>
</section>

<section class="blog-grid-layout">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6" *ngFor="let partner of partners">
        <div class="blog-grid-layout__item">
          <div class="blog-grid-layout__item__thumb">
            <a href="{{ partner.link }}" target="_blank">
              <img
                src="{{'assets/images/partner/' + partner?.id + '.jpg'}}"
                alt=""
              />
            </a>
          </div>
          <div class="blog-grid-layout__item__info">
            <div class="blog-grid-layout__item__info__time"></div>
            <h3>{{ partner.name }}</h3>
            <p>
              {{ partner.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
