<app-wizard
  *ngIf="wizardOpened"
  (closingWizard)="openWizard(false)"
  [startWithHike]="wizardOpenedHike"
  [startWithTour]="wizardOpenedTour"
  [disableBackdrop]="true"
></app-wizard>
<header id="header-1">
  <div class="wand-container">
    <div class="header-content1">
      <div class="header-content1__logo">
        <a class="header-logo" href="index.html"
          ><img src="assets/images/logo.png" alt="logo" class="header_logo"
        /></a>
      </div>
      <nav class="header-1-nav">
        <ul>
          <li>
            <a [routerLink]="['/startseite']" routerLinkActive="marked1"
              >STARTSEITE
            </a>
          </li>
          <li>
            <a (click)="toggleSubpage(0)"
              >TOUREN <i class="fa fa-angle-down"></i
            ></a>
            <ul class="sub-menu" [ngClass]="{ opened: subpageOpened[0] }">
              <li *ngFor="let tour of tours">
                <a (click)="openTour(tour.id)">{{ tour.name.toUpperCase() }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/gutschein']" routerLinkActive="marked1"
              >GUTSCHEIN</a
            >
          </li>
          <li>
            <a (click)="toggleSubpage(1)"
              >SOCIAL MEDIA <i class="fa fa-angle-down"></i
            ></a>
            <ul class="sub-menu" [ngClass]="{ opened: subpageOpened[1] }">
              <li>
                <a href="https://www.facebook.com/goenndirwatt/" target="_blank"
                  >FACEBOOK</a
                >
              </li>
              <li>
                <a
                  href="https://www.instagram.com/goenndirwatt/"
                  target="_blank"
                  >INSTAGRAM</a
                >
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCemGi3M6sZNFh6QJXzdy65Q"
                  target="_blank"
                  >YOUTUBE</a
                >
              </li>
              <li>
                <a
                  href="https://www.cnv-medien.de/news/cuxhaven-wanderung-im-winter-watt-reise-zum-sahlenburger-loch.html"
                  target="_blank"
                  >CUXHAVENER NACHRICHTEN</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/partner']" routerLinkActive="marked1"
              >PARTNER</a
            >
          </li>
          <li>
            <a [routerLink]="['/kontakt']" routerLinkActive="marked1"
              >KONTAKT</a
            >
          </li>
        </ul>
      </nav>
      <nav class="header-nav-mobile" [ngClass]="{ opened: hamburgerOpened }">
        <ul>
          <li>
            <a [routerLink]="['/startseite']" routerLinkActive="marked2"
              >STARTSEITE
            </a>
          </li>
          <li>
            <a (click)="toggleSubpage(0)"
              >TOUREN <i class="fa fa-angle-down"></i
            ></a>
            <ul class="sub-menu" [ngClass]="{ opened: subpageOpened[0] }">
              <li *ngFor="let tour of tours">
                <a (click)="openTour(tour.id)">{{ tour.name.toUpperCase() }}</a>
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/gutschein']" routerLinkActive="marked1"
              >GUTSCHEIN</a
            >
          </li>
          <li>
            <a (click)="toggleSubpage(1)"
              >SOCIAL MEDIA <i class="fa fa-angle-down"></i
            ></a>
            <ul class="sub-menu" [ngClass]="{ opened: subpageOpened[1] }">
              <li>
                <a href="https://www.facebook.com/goenndirwatt/" target="_blank"
                  >FACEBOOK</a
                >
              </li>
              <li>
                <a
                  href="https://www.instagram.com/goenndirwatt/"
                  target="_blank"
                  >INSTAGRAM</a
                >
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCemGi3M6sZNFh6QJXzdy65Q"
                  target="_blank"
                  >YOUTUBE</a
                >
              </li>
              <li>
                <a
                  href="https://www.cnv-medien.de/news/cuxhaven-wanderung-im-winter-watt-reise-zum-sahlenburger-loch.html"
                  target="_blank"
                  >CUXHAVENER NACHRICHTEN</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/partner']" routerLinkActive="marked2"
              >PARTNER</a
            >
          </li>
          <li>
            <a [routerLink]="['/kontakt']" routerLinkActive="marked2"
              >KONTAKT</a
            >
          </li>
        </ul>
      </nav>

      <div
        class="header-content1__hamburger"
        (click)="toggleHamburger()"
        [ngClass]="{ 'ham-open': hamburgerOpened }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <div id="form-1-area">
    <button class="home-booking-button" (click)="openWizard()" type="submit">
      Verfügbare Touren suchen & buchen
    </button>
    <div class="scroll-down-icon">
      <span>weiter unten finden Sie noch weitere Informationen</span>
      <i class="fas fa-chevron-down"></i>
    </div>
  </div>
</header>

<section>
  <app-tour-list-horizontal></app-tour-list-horizontal>
</section>

<!--<section>
  <div class="container">
    <div class="top-desti__tittle">
      <div class="section-tittle">
        <h2>GOOGLE</h2>
        <div class="section-tittle__line-under"></div>
        <p><span>REVIEWS</span></p>
      </div>
    </div>
  </div>

  <div class="top-desti"></div>
</section>-->

<section>
  <app-team></app-team>
</section>

<app-footer></app-footer>
