<div class="container">
  <div class="top-desti__tittle">
    <div class="section-tittle" *ngIf="excludeTourId">
      <h2>entdecken sie</h2>
      <div class="section-tittle__line-under"></div>
      <p>unsere weiteren <span>Touren</span></p>
    </div>
    <div class="section-tittle" *ngIf="!excludeTourId">
      <h2>entdecken sie</h2>
      <div class="section-tittle__line-under"></div>
      <p>unsere <span>Touren</span></p>
    </div>
  </div>
</div>

<div class="similar-tour">
  <div class="container">
    <div class="row">
      <div
        *ngIf="tours?.length === 0"
        class="col-12"
        style="text-align: center"
      >
        <i class="far fa-frown"></i><br />
        Leider konnten wir keine Touren finden, schauen Sie doch gerne später
        noch einmal vorbei.
      </div>
      <div
        class="col-lg-4 col-md-6 col-xl-3 col-sm-6 col-12"
        *ngFor="let tour of tours"
      >
        <a (click)="openTour(tour.id)" class="trending-tour-item" style="cursor: pointer;">
          <div class="trending-tour-item__sale" *ngIf="tour.sale"></div>
          <img
            class="trending-tour-item__thumnail"
            src="{{'assets/images/tours/' + tour?.id + '.jpg'}}"
            alt="tour1"
          />
          <div class="trending-tour-item__info">
            <h3 class="trending-tour-item__name">{{ tour?.name }}</h3>
            <div class="trending-tour-item__group-infor">
              <div class="trending-tour-item__group-infor--left">
                <div
                  class="trending-tour-item__group-infor__lasting"
                  *ngIf="tour?.duration"
                >
                  <img src="assets/images/tours/lasting.png" alt="lasting" />
                  {{ tour.duration }}
                </div>
                <div
                  class="trending-tour-item__group-infor__lasting"
                  *ngIf="tour?.min_age"
                >
                  <img
                    src="assets/images/tours/tour_item-icon-age-range.png"
                    alt="min_age"
                  />
                  {{ tour.min_age }}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
