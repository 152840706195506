<header id="header-2">
  <div class="wand-container">
    <div class="header-content2">
      <div class="header-content2__logo">
        <a class="header-content2__logo__sitename" href="index.html"><img src="assets/images/logo.png" alt="logo"
            class="header_logo" /></a>
      </div>

      <nav class="header-2-nav">
        <ul>
          <li>
            <a [routerLink]="['/startseite']" routerLinkActive="marked2">STARTSEITE
            </a>
          </li>
          <li>
            <a (click)="toggleSubpage(0)">TOUREN <i class="fa fa-angle-down"></i></a>
            <ul class="sub-menu" [ngClass]="{'opened': subpageOpened[0]}">
              <li *ngFor="let tour of tours"><a (click)="openTour(tour.id)">{{ tour.name.toUpperCase() }}</a></li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/gutschein']" routerLinkActive="marked2">GUTSCHEIN</a>
          </li>
          <li>
            <a (click)="toggleSubpage(1)">SOCIAL MEDIA <i class="fa fa-angle-down"></i></a>
            <ul class="sub-menu" [ngClass]="{'opened': subpageOpened[1]}">
              <li>
                <a href="https://www.facebook.com/goenndirwatt/" target="_blank">FACEBOOK</a>
              </li>
              <li>
                <a href="https://www.instagram.com/goenn_dir_watt" target="_blank">INSTAGRAM</a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCemGi3M6sZNFh6QJXzdy65Q" target="_blank">YOUTUBE</a>
              </li>
              <li>
                <a href="https://www.cnv-medien.de/news/cuxhaven-wanderung-im-winter-watt-reise-zum-sahlenburger-loch.html"
                  target="_blank">CUXHAVENER NACHRICHTEN</a>
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/partner']" routerLinkActive="marked2">PARTNER</a>
          </li>
          <li>
            <a [routerLink]="['/kontakt']" routerLinkActive="marked2">KONTAKT</a>
          </li>
          <li>
            <a [routerLink]="['/buchen']" routerLinkActive="marked2">BUCHEN</a>
          </li>
        </ul>
      </nav>
      <nav class="header-nav-mobile" [ngClass]="{'opened': hamburgerOpened}">
        <ul>
          <li>
            <a [routerLink]="['/startseite']" routerLinkActive="marked1">STARTSEITE
            </a>
          </li>
          <li>
            <a (click)="toggleSubpage(0)">TOUREN <i class="fa fa-angle-down"></i></a>
            <ul class="sub-menu" [ngClass]="{'opened': subpageOpened[0]}">
              <li *ngFor="let tour of tours"><a (click)="openTour(tour.id)">{{ tour.name.toUpperCase() }}</a></li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/gutschein']" routerLinkActive="marked1">GUTSCHEIN</a>
          </li>
          <li>
            <a (click)="toggleSubpage(1)">SOCIAL MEDIA <i class="fa fa-angle-down"></i></a>
            <ul class="sub-menu" [ngClass]="{'opened': subpageOpened[1]}">
              <li>
                <a href="https://www.facebook.com/goenndirwatt/" target="_blank">FACEBOOK</a>
              </li>
              <li>
                <a href="https://www.instagram.com/goenndirwatt/" target="_blank">INSTAGRAM</a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCemGi3M6sZNFh6QJXzdy65Q" target="_blank">YOUTUBE</a>
              </li>
              <li>
                <a href="https://www.cnv-medien.de/news/cuxhaven-wanderung-im-winter-watt-reise-zum-sahlenburger-loch.html"
                  target="_blank">CUXHAVENER NACHRICHTEN</a>
              </li>
            </ul>
          </li>
          <li>
            <a [routerLink]="['/partner']" routerLinkActive="marked1">PARTNER</a>
          </li>
          <li>
            <a [routerLink]="['/kontakt']" routerLinkActive="marked1">KONTAKT</a>
          </li>
          <li>
            <a [routerLink]="['/buchen']" routerLinkActive="marked1">BUCHEN</a>
          </li>
        </ul>
      </nav>

      <div class="header-content2__hamburger" (click)="toggleHamburger()" [ngClass]="{'ham-open': hamburgerOpened}">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</header>