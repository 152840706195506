<app-header></app-header>
<div class="container" style="margin-top: 5%; margin: 10px">
  <div *ngFor="let data of dataprivicies">
    <h2>
      {{ data.title }}
    </h2>

    <p [innerHTML]="data.body.body"></p>
  </div>
</div>
<app-footer></app-footer>
