<app-header></app-header>
<div class="container" style="margin-top: 5%; margin: 10px">
  <div *ngFor="let imprint of imprints">
    <h2>
      {{ imprint.title }}
    </h2>

    <p [innerHTML]="imprint.body.body"></p>
  </div>
</div>
<app-footer></app-footer>
