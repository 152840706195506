export class ScrollHelper {
  private styleTag: HTMLStyleElement;

  constructor() {
    this.styleTag = this.buildStyleElement();
  }

  // I disable the scrolling feature on the main viewport.
  public disableScroll(): void {
    document.body.appendChild(this.styleTag);
  }

  // I re-enable the scrolling feature on the main viewport.
  public enableScroll(): void {
    document.body.removeChild(this.styleTag);
  }

  // Build and return a Style element that will prevent scrolling on the body.
  private buildStyleElement(): HTMLStyleElement {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.setAttribute('data-debug', 'Injected by WindowScrolling service.');
    style.textContent = `
			body {
				overflow: hidden !important;
        position: fixed !important;
			}
		`;
    return style;
  }
}
