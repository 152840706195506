import { ContactService } from "./../../provider/model-services/contact-service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactModel } from "src/app/models/contact";

export interface KontaktModel {
  year: string;
}
@Component({
  selector: "app-kontakt",
  templateUrl: "./kontakt.component.html",
  styleUrls: ["./kontakt.component.scss"],
})
export class KontaktComponent implements OnInit {
  year: number = new Date().getFullYear();

  contact: ContactModel;

  contactForm: FormGroup;
  constructor(private fb: FormBuilder, private contactService: ContactService) {
    this.contactForm = this.fb.group({
      user_name: ["", Validators.required],
      user_email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required],
      checkbox: [false, Validators.requiredTrue],
    });
    this.resetData();
  }

  ngOnInit(): void {}

  resetData() {
    this.contact = {
      id: null,
      created_at: null,
      updated_at: null,
      name: "",
      description: "",
      body: "",
    };
  }

  public sendEmail(e: Event) {
    e.preventDefault();
    this.contactService.create(this.contact).subscribe(() => {
      this.resetData();
    });
  }
}
