import { ProvidersModule } from "./provider/providers.module";
import { FooterComponent } from "./components/footer/footer.component";
import { ComponentsModule } from "./components/components.module";
import { HomeComponent } from "./pages/home/home.component";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { init } from "emailjs-com";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TeamComponent } from "./components/team/team.component";
import { LoginComponent } from "./pages/login/login.component";
import { PartnerComponent } from "./pages/partner/partner.component";
import { KontaktComponent } from "./pages/kontakt/kontakt.component";
import { GutscheinComponent } from "./pages/gutschein/gutschein.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TourDetailComponent } from "./pages/tour-detail/tour-detail.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { HttpClientModule } from "@angular/common/http";
import { WizardComponent } from "./components/wizard/wizard.component";
import { TourListHorizontalComponent } from "./components/tour-list-horizontal/tour-list-horizontal.component";
import { ImprintComponent } from "./pages/imprint/imprint.component";
import { DataprivacyComponent } from "./pages/dataprivacy/dataprivacy.component";
import { AgbComponent } from "./pages/agb/agb.component";

init("user_vOqO6beeug68qdL0fhH9p");
@NgModule({
  declarations: [
    AppComponent,
    TeamComponent,
    HomeComponent,
    LoginComponent,
    PartnerComponent,
    KontaktComponent,
    GutscheinComponent,
    HeaderComponent,
    FooterComponent,
    TourDetailComponent,
    WizardComponent,
    TourListHorizontalComponent,
    ImprintComponent,
    DataprivacyComponent,
    AgbComponent,
  ],
  imports: [
    ProvidersModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "de-DE" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
