import { Component, OnInit } from '@angular/core';
import { TeamModel } from 'src/app/models/team';
import { TeamService } from 'src/app/provider/model-services/team-service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
  teams: TeamModel[] = [];
  constructor(private teamService: TeamService) {}

  ngOnInit() {
    this.teamService.all().subscribe((result) => {
      this.teams = result;
    });
  }
}
