<app-header></app-header>
<section class="tour-infomation-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <aside>
          <div class="tour-infomation__content">
            <div class="tour-infomation__content__header">
              <h2>{{ selectedTour?.name }}</h2>
              <div class="tour-infomation_basics" *ngIf="!selectedTour?.special">
                <p>
                  <img src="assets/images/tours/tour_item-icon-lasting.png" alt="" />{{ selectedTour?.duration }}
                </p>
                <p>
                  <img src="assets/images/tours/tour_item-icon-address.png" alt="" />{{ selectedTour?.start }}
                </p>
                <p>
                  <img src="assets/images/tours/tour_item-icon-age-range.png" alt="" />{{ selectedTour?.min_age }}
                </p>
              </div>
            </div>

            <div class="tour-infomation__content__descript">
              <h2>Beschreibung</h2>
              <p>
                {{ selectedTour?.description }}
              </p>
            </div>

            <!-- tour-infomation__content__time-table -->
            <div class="tour-infomation__content__time-table--price" *ngIf="!selectedTour?.special">
              <span>erforderliche Ausrüstung:</span>
              <ul class="tour-infomation__content__time-table__price-include">
                <li *ngIf="selectedTour?.name == 'WATT FÜR ALLE'">
                  barfuß möglich
                </li>
                <li *ngIf="selectedTour?.name == 'WATT FÜR ALLE'">
                  weitere tourbezogene Tipps zu Kleidung und Ausrüstung erhalten
                  Sie mit dem Briefing am Vortag per Mail
                </li>
                <li *ngIf="selectedTour?.name == 'TÜRME UND PRIELE'">
                  Sie erhalten nach Ihrer Anmeldung eine tourbezogene Packliste
                  am Vortag senden wir Ihnen ein Briefing mit aktuellen Tipps
                </li>
                <li *ngIf="selectedTour?.name == 'SEEHUNDE VORAUS'">
                  Sie erhalten nach Ihrer Anmeldung eine tourbezogene Packliste
                </li>
                <li *ngIf="selectedTour?.name == 'SEEHUNDE VORAUS'">
                  am Vortag senden wir Ihnen ein Briefing mit aktuellen Tipps
                </li>
                <li *ngIf="selectedTour?.name == 'INSEL NEUWERK'">
                  Sie erhalten nach Ihrer Anmeldung eine tourbezogene Packliste
                </li>
              </ul>
            </div>
            <div class="tour-infomation__content__time-table--price piceList" *ngIf="!selectedTour?.special">
              <span>Preise</span>
              <ul>
                <li>
                  Erwachsener: {{ selectedTour.price_adult }}€
                </li>
                <li>
                  Kinder <span *ngIf="selectedTour.id == 19">(4 bis 14 Jahre)</span>: {{ selectedTour.price_kids }}€
                </li>
                <li *ngIf="selectedTour.price_family && selectedTour.price_family != 0">
                  Familien: {{ selectedTour.price_family }}€
                </li>
                <li *ngIf="selectedTour.price_sundowner">
                  Aufpreis Sundowner Einzeltickets:
                  {{ selectedTour.price_sundowner }}€
                </li>
                <li *ngIf="selectedTour.price_sundowner_family">
                  Aufpreis Sundowner Familientickets:
                  {{ selectedTour.price_sundowner_family }}€
                </li>
              </ul>
            </div>
            <div class="tour-infomation__content__time-table--price" *ngIf="_services?.length > 0">
              <span>Im Preis enthalten</span>
              <ul class="tour-infomation__content__time-table__price-include">
                <li *ngFor="let item of _services">{{ item }}</li>
              </ul>
            </div>

            <div class="itemWrapper" *ngIf="selectedTour?.special">
              <div class="contactButton" routerLink="/kontakt">
                Kontaktieren Sie uns
              </div>
            </div>
            <!-- end tour-infomation__content__time-table -->
            <div class="gallery-syncing-item" *ngIf="!selectedTour?.special">
              <div class="gallery-syncing-item__single">
                <div *ngFor="let image of selectedTour?.gallery" class="gallery-syncing-item__single__item">
                  <img src="{{ 'http://199.247.16.134:3000' + image }}" alt="gall-syn1" />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>

      <div class="col-lg-4" *ngIf="selectedTour?.special">
        <div class="sidebar">
          <div class="right-sidebar">
            <img src="{{ 'assets/images/tours/' + selectedTour?.id + '.jpg' }}" class="specialTourPicture" />
          </div>
        </div>
      </div>
      <!-- sidebar -->
      <div class="col-lg-4 hideOnMobile" *ngIf="!selectedTour?.special">
        <div class="sidebar">
          <div class="right-sidebar">
            <div class="right-sidebar__item">
              <h3 style="height: 100%">Anmeldung</h3>
              <div class="right-sidebar__item__form" *ngIf="selectedTour">
                <label>Tour</label>
                <input name="email" type="text" [(ngModel)]="selectedTour.name" disabled />
                <label>Ausflugsdatum *</label>
                <mat-form-field style="width: 1px; height: 0px; visibility: hidden">
                  <input matInput [matDatepickerFilter]="sidemenuDateFilter" [matDatepicker]="picker"
                    [(ngModel)]="selectedDate" (dateChange)="changedDate()" />
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <span *ngIf="selectedDate">{{
                  selectedDate | date: "dd.MM.yyyy"
                  }}</span>
                <span *ngIf="!selectedDate">Datum auswählen...</span>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <input type="submit" value="JETZT BUCHEN" (click)="bookNow()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-tour-list-horizontal *ngIf="selectedTour?.id" [excludeTourId]="selectedTour.id"></app-tour-list-horizontal>
  </div>
</section>

<input class="floatingButton showOnMobile" type="submit" value="JETZT BUCHEN" (click)="bookNow(true)" />

<app-footer></app-footer>