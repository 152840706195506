import { HikeModel } from './../../models/hike';
import { TourService } from './../../provider/model-services/tour-service';
import { TourModel } from 'src/app/models/tour';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalDataService } from 'src/app/provider/helper/global-data';

@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.scss'],
})
export class TourDetailComponent implements OnInit {
  tourId: number;
  selectedTour?: TourModel;
  selectedHike?: HikeModel;
  selectedDate?: Date;

  _services: string[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private tourService: TourService,
    private router: Router,
    private globalData: GlobalDataService
  ) {
    this.tourId = this.activeRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getTour(this.tourId);
  }

  bookNow(fastForward?: boolean) {
    if (!(this.selectedDate && this.selectedTour) && !fastForward) {
      this.snackBar.open(
        'Bitte tragen Sie in alle Felder gültige Werte ein!',
        'Ok'
      );
      return;
    }
    this.openWizard();
  }

  openWizard() {
    this.globalData.wizardData.hike = this.selectedHike;
    this.globalData.wizardData.tour = this.selectedTour;
    this.globalData.wizardData.openWizard = true;
    this.router.navigate(['./buchen'], {});
  }

  changedDate() {
    this.selectedHike = this.selectedTour.hikes.find((h) => {
      const rt: Date = new Date(
        new Date(h.runtime).toISOString().substring(0, 10)
      );
      const ndMillisDayAfter = new Date(this.selectedDate.getTime() + 86400000);
      const nd: Date = new Date(
        ndMillisDayAfter.toISOString().substring(0, 10)
      );
      if (rt.getTime() === nd.getTime()) {
        return true;
      }
    });
  }

  sidemenuDateFilter = (d: Date): boolean => {
    if (!d) return false;
    const nd: Date = new Date(d.toISOString().substring(0, 10));
    return (
      this.selectedTour.hikes.findIndex((h) => {
        const rt = new Date(new Date(h.runtime).toISOString().substring(0, 10));
        return nd.getTime() === rt.getTime() - 86400000;
      }) != -1
    );
  };

  private getTour(id: number) {
    this.tourService.find(id).subscribe((res) => {
      this.selectedTour = res;
      if (this.selectedTour.included_options === '') {
        this._services = [];
      } else {
        this._services = this.selectedTour.included_options.split(',');
      }
    });
  }
}
