<footer class="footer-2">
  <div class="scroll-top">
    <i class="fas fa-angle-up"></i>
  </div>

  <div class="logo-and-text-area">
    <img src="./../../../assets/images/logo.png">
    <span>
      Gönn dir Watt richtet sich an Gäste, die einen perfekten Tag im Cuxhavener Wattenmeer verbringen möchten. Wir
      übernehmen die Planung bereits weit vor Beginn der Cuxhaven Wattwanderung und erst nach Rückkehr in Ihrem
      Urlaubsquartier endet unser Engagement für Sie. Die Tour wird von uns ganzheitlich zusammengestellt, wir betrachten
      es
      als unsere Aufgabe, Ihren schönsten Urlaubstag auszurichten. Eine detaillierte Vorbereitung, die für Sie getroffenen
      Reservierungen und eine bevorzugte Betreuung werden Ihnen lästige Wartezeiten, Anstehen und weitere
      Unannehmlichkeiten
      ersparen. Genießen Sie die Weite des Weltnaturerbes Wattenmeer und seine einzigartigen Besonderheiten und lassen Sie
      an unserem gemeinsamen Tag die Sorgen mal am Strand. Wattführungen in Cuxhaven oder eine Wattwanderung nach Neuwerk,
      eine Wohltat für Körper und Seele, komplett durchgeplant durch die Wohlfühlspezialisten in Cuxhaven-Duhnen.
    </span>
  </div>

  <div class="copyright">
    <div class="container">
      <div class="copyright__area">
        <div class="copyright__license">
          Copyright <i class="far fa-copyright"></i> {{ year }} GoennDirWatt.
          All Rights Reserved.
        </div>
        <div class="copyright__social">
          <a class="copyright__link" routerLink="/startseite">Startseite</a>
          <a class="copyright__link" routerLink="/partner">Partner</a>
          <a class="copyright__link" [routerLink]="['/kontakt']">Kontakt</a>
          <a class="copyright__link" routerLink="/datenschutz">Datenschutz</a>
          <a class="copyright__link" routerLink="/agb">AGB</a>
          <a class="copyright__link" routerLink="/impressum">Impressum</a>
        </div>
      </div>
    </div>
  </div>
</footer>
