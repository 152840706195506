import { Injectable } from "@angular/core";
import { ImprintModel } from "src/app/models/imprint";
import { DataService } from "../data-service";

@Injectable({
  providedIn: "root",
})
export class ImprintService extends DataService<ImprintModel> {
  endpoint = "imprints";
  objectName = "imprint";
}
