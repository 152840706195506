import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TeamComponent } from "./components/team/team.component";
import { AgbComponent } from "./pages/agb/agb.component";
import { DataprivacyComponent } from "./pages/dataprivacy/dataprivacy.component";
import { GutscheinComponent } from "./pages/gutschein/gutschein.component";
import { HomeComponent } from "./pages/home/home.component";
import { ImprintComponent } from "./pages/imprint/imprint.component";
import { KontaktComponent } from "./pages/kontakt/kontakt.component";
import { PartnerComponent } from "./pages/partner/partner.component";
import { TourDetailComponent } from "./pages/tour-detail/tour-detail.component";

const routes: Routes = [
  { path: "", redirectTo: "startseite", pathMatch: "full" },
  { path: "startseite", component: HomeComponent },
  { path: "team", component: TeamComponent },
  { path: "kontakt", component: KontaktComponent },
  { path: "partner", component: PartnerComponent },
  { path: "gutschein", component: GutscheinComponent },
  { path: "tour-details/:id", component: TourDetailComponent },
  { path: "agb", component: AgbComponent },
  { path: "datenschutz", component: DataprivacyComponent },
  { path: "impressum", component: ImprintComponent },
  {
    path: "buchen",
    redirectTo: "startseite",
    data: { openWizardDirectly: true },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
