import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { BasicModel } from "../models/basic";

@Injectable()
export class DataService<T extends BasicModel> {
  public currentlySaving: boolean = false;

  protected removeParams = ["created_at", "updated_at"];

  endpoint: string = "shouldBeOverwritten";
  objectName: string = "shouldBeOverwritten";

  constructor(public http: HttpClient) {}

  get url(): string {
    return DataService.serviceUrl;
  }

  public static get serviceUrl(): string {
    let url = window.location.toString();
    if (url.indexOf("localhost") >= 0) {
      return "https://goenndirwatt.de/admin/";
    } else {
      return `https://goenndirwatt.de/admin/`;
    }
  }

  /**
   * Endpoint with URL
   * @return {string}
   */
  get endpointWithUrl(): string {
    return this.url + this.endpoint;
  }

  /**
   * HTTP Options
   */
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  /**
   * Erhält alle Objecte vom Endpunkt.
   */
  all(): Observable<T[]> {
    return this.http
      .get<T[]>(this.endpointWithUrl + ".json")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Ruft ein Objekt mit Details ab
   * @param id
   */
  find(id): Observable<T> {
    return this.http
      .get<T>(this.endpointWithUrl + "/" + id + ".json")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Sucht Objekte mit Argumenten
   * @param args
   */
  where(args): Observable<T[]> {
    return this.http
      .get<T[]>(this.endpointWithUrl + ".json", { params: args })
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Erzeugt ein neues Objekt im Backend
   * @param object
   */
  create(object): Observable<T> {
    return this.http
      .post<T>(
        this.endpointWithUrl + ".json",
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Aktualisiert ein Objekt
   * @param id
   * @param object
   */
  update(id, object): Observable<T> {
    return this.http
      .put<T>(
        this.endpointWithUrl + "/" + id + ".json",
        this.formatPayload(object),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Löscht ein Objekt im Backend
   * @param id
   */
  delete(id) {
    return this.http
      .delete<T>(this.endpointWithUrl + "/" + id + ".json")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Error handling
   * @param error
   */
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public formatPayload(obj: T) {
    let hashToSend = {};
    hashToSend[this.objectName] = obj;
    return JSON.stringify(hashToSend);
  }
}
