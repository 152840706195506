import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-reviews',
  templateUrl: './google-reviews.component.html',
  styleUrls: ['./google-reviews.component.scss']
})
export class GoogleReviewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
