import { MatSnackBar } from "@angular/material/snack-bar";
import { GlobalDataService } from "./../../provider/helper/global-data";
import { HikeModel } from "src/app/models/hike";
import { Router, ActivatedRoute } from "@angular/router";
import { TourService } from "./../../provider/model-services/tour-service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TourModel } from "src/app/models/tour";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  /** Alle Touren welche auf der Seite dargestellt werden */
  tours: TourModel[] = [];
  /** Ist der Wizard geöffnet? */
  wizardOpened?: boolean = false;
  wizardOpenedHike?: HikeModel;
  wizardOpenedTour?: TourModel;

  hamburgerOpened: boolean = false;
  subpageOpened: boolean[] = [false, false];

  constructor(
    private tourService: TourService,
    private router: Router,
    private globalData: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.getAllTours();
    if (this.globalData.wizardData.openWizard == true) {
      this.wizardOpenedHike = this.globalData.wizardData.hike;
      this.wizardOpenedTour = this.globalData.wizardData.tour;
      this.globalData.wizardData.hike = null;
      this.globalData.wizardData.tour = null;
      this.globalData.wizardData.openWizard = false;
      this.openWizard(true);
    }
  }

  toggleSubpage(index: number) {
    this.subpageOpened[index] = !this.subpageOpened[index];
  }

  toggleHamburger() {
    this.hamburgerOpened = !this.hamburgerOpened;
  }

  getAllTours() {
    this.tourService.all().subscribe((res) => {
      this.tours = res;
    });
  }

  openTour(id: number) {
    this.router.navigate(["/"]).then(() => {
      this.router.navigate(["/tour-details/" + id], {});
    });
  }

  /**
   * Öffnet / Schließt den Wizard
   * @param open Soll der Wizard geöffnet werden?
   *             (Wenn false dann wird er geschlossen)
   *             (Wenn nicht übergeben, wird status gewechselt)
   */
  openWizard(open?: boolean): void {
    if (open !== undefined) {
      this.wizardOpened = open;
      if (!open) {
        this.wizardOpenedHike = null;
        this.wizardOpenedTour = null;
      }
      return;
    }
    this.wizardOpened = !this.wizardOpened;
  }
}
