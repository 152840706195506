import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { TourModel } from "src/app/models/tour";
import { TourService } from "src/app/provider/model-services/tour-service";

@Component({
  selector: "app-tour-list-horizontal",
  templateUrl: "./tour-list-horizontal.component.html",
  styleUrls: ["./tour-list-horizontal.component.scss"],
})
export class TourListHorizontalComponent implements OnInit {
  @Input() excludeTourId: number;

  tours: TourModel[] = [];

  constructor(private tourService: TourService, private router: Router) {
    this.loadTours();
  }

  ngOnInit(): void {}

  /**
   * Lädt alle Touren und filtert diese direkt
   */
  loadTours() {
    this.tourService.all().subscribe((res) => {
      this.tours = res.filter((t) => t.id !== this.excludeTourId);
    });
  }

  openTour(id: number) {
    this.router.navigate(["/"]).then(() => {
      this.router.navigate(["/tour-details/" + id], {});
    });
  }
}
