import { BasicModel } from './../../models/basic';
import { Injectable } from '@angular/core';
import { DataService } from '../data-service';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HikeModel } from 'src/app/models/hike';

@Injectable({
  providedIn: 'root',
})
export class WizardService extends DataService<HikeModel> {
  endpoint = 'wizard';
  objectName = 'wizard';

  public getAvailableDates(): Observable<string[]> {
    return this.http
      .get<string[]>(this.endpointWithUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  public generateTourData(
    date: string,
    tourId?: number
  ): Observable<HikeModel[]> {
    const args: any = {
      date,
    };
    if (tourId >= 0) {
      args['tour_id'] = tourId;
    }
    return this.http
      .get<HikeModel[]>(this.endpointWithUrl, { params: args })
      .pipe(retry(1), catchError(this.handleError));
  }
}
