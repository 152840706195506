import { BookingService } from './../../provider/model-services/booking-service';
import { BookingModel } from './../../models/booking';
import { ScrollHelper } from './../../provider/helper/scroll-helper';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HikeModel } from 'src/app/models/hike';
import { WizardService } from 'src/app/provider/interfaces/wizard-service';
import { TourModel } from 'src/app/models/tour';

@Component({
  selector: "app-wizard",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.scss"],
})
export class WizardComponent implements OnInit, OnDestroy {
  /** Soll das schließen über den klick in den Hintergrund abgeschaltet werden? */
  @Input() disableBackdrop: boolean = false;
  /** Hike welcher vorausgewählt wird */
  @Input() startWithHike?: HikeModel;
  /** Tour welche mit dem Hike vorausgewählt wird */
  @Input() startWithTour?: TourModel;
  /** Event welches ausgelöst wird, wenn der Wizard geschlossen werden soll */
  @Output() closingWizard = new EventEmitter<void>();
  /** Ausgewähltes Datum */
  selectedDate: Date | null;
  /** Daten aus dem Backend zum Wizard */
  wizardData: HikeModel[];
  /** Alle Steps */
  steps: Array<string> = [
    "Datum wählen",
    "Tour finden",
    "Weitere Angaben",
    "Abschließen",
  ];
  /** Aktueller Step Index */
  currentStep: number = 0;
  /** Buchungsangaben */
  booking: BookingModel = {
    id: undefined,
    created_at: undefined,
    updated_at: undefined,
    hike: undefined,
    hike_id: undefined,
    family_tickets: 0,
    family_adult_count: 0,
    family_child_count: 0,
    child_tickets: 0,
    adult_tickets: 0,
    dog_tickets: 0,
    hotel_name: undefined,
    name: undefined,
    street_and_number: undefined,
    zip_and_city: undefined,
    tel_number: undefined,
    mail: undefined,
  };
  /** Buchungsart / Familie oder Einzeln */
  booking_type: string;
  /** Geschäftsbedingungen sowie Widerrufsbelehrung akzeptiert */
  accepted_first: boolean = false;
  /** Datenschutzerklärung akzeptiert */
  accepted_second: boolean = false;

  /** Datum bis wann NICHT ausgewählt werden darf */
  disableToDate: Date;
  /** Diese Dates haben Touren hinterlegt */
  allHikeDates: Date[];

  /** FormGroup für die persöhnlichen Informationen zur Buchung */
  personalDataFormGroup: FormGroup;

  wizardDateFilter = (d: Date): boolean => {
    return (
      this.allHikeDates.findIndex((hdt) => {
        return this.checkDate(hdt, d);
      }) != -1
    );
  };

  constructor(
    private wizardService: WizardService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private scrollHelper: ScrollHelper,
    private bookingService: BookingService
  ) {
    this.resetData();
  }

  ngOnInit(): void {
    this.personalDataFormGroup = this.formBuilder.group({
      nameCtrl: ["", Validators.required],
    });
    this.scrollHelper.disableScroll();
    if (this.startWithHike && this.startWithTour) {
      this.selectedDate = new Date(this.startWithHike.runtime);
      this.wizardData = [this.startWithHike];
      this.wizardData[0].tour = this.startWithTour;
      this.booking.hike = this.startWithHike;
      this.currentStep = 2;
    }
    this.wizardService.getAvailableDates().subscribe((res) => {
      this.allHikeDates = res.map((d) => {
        return new Date(d);
      });
    });
  }

  ngOnDestroy(): void {
    this.scrollHelper.enableScroll();
  }

  resetData() {
    const disDate = new Date();
    disDate.setDate(new Date().getDate() - 1);
    this.disableToDate = disDate;
    this.booking = {
      id: undefined,
      created_at: undefined,
      updated_at: undefined,
      hike: undefined,
      hike_id: undefined,
      family_tickets: 0,
      family_adult_count: 0,
      family_child_count: 0,
      child_tickets: 0,
      adult_tickets: 0,
      dog_tickets: 0,
      hotel_name: undefined,
      name: undefined,
      street_and_number: undefined,
      zip_and_city: undefined,
      tel_number: undefined,
      mail: undefined,
    };
    this.booking_type = null;
    this.accepted_first = false;
    this.accepted_second = false;
    this.currentStep = 0;
    this.wizardData = [];
    this.selectedDate = null;
  }

  /**
   * Wird aufgerufen wenn das Datum verändert wurde
   */
  changedDate() {
    this.selectHike(null);
  }

  /**
   * Wenn der Hintergrund angeklickt wurde
   * @param ev Event
   */
  clickedBackdrop(ev: Event): void {
    if (this.disableBackdrop) {
      return;
    }
    this.closeWizard();
  }

  /**
   * Wenn das Modal angeklickt wurde
   * @param ev Event
   */
  clickedModal(ev: Event): void {
    ev.stopPropagation();
  }

  /**
   * Ist der übergebene Step disabled?
   */
  isDisabled(index: number): boolean {
    if (this.startWithTour && this.startWithHike && index <= 1) {
      return true;
    } else if (index === 1) {
      // const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      // const selected = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
      return !this.selectedDate;
    } else if (index === 2) {
      return !(this.booking.hike != null);
    } else if (index === 3) {
      return !(
        (this.booking.adult_tickets > 0 ||
          this.booking.child_tickets > 0 ||
          (this.booking.family_tickets > 0 &&
            this.booking.family_adult_count > 0 &&
            this.booking.family_child_count > 0)) &&
        this.booking.hike &&
        this.booking.mail &&
        this.booking.name &&
        this.booking.street_and_number &&
        this.booking.tel_number &&
        this.booking.zip_and_city
      );
    } else if (index === 4) {
      return !(this.accepted_first && this.accepted_second);
    }
    return false;
  }

  /**
   * Stezt den neuen Step-Index
   * @param index Index
   */
  setStep(index: number) {
    if (this.isDisabled(index)) return;
    this.currentStep = index;
    if (this.currentStep < 0) {
      this.closeWizard();
    } else if (this.currentStep >= this.steps.length) {
      this.snackBar.open("Ihre Buchung war erfolgreich!", "Schließen");
      this.booking.hike_id = this.booking.hike.id;
      this.booking.hike.tour = null;
      this.bookingService.create(this.booking).subscribe(() => {});
      this.closeWizard();
    }
    if (this.currentStep === 1) {
      const requestDate = new Date(this.selectedDate.getTime() + 86400000);
      this.wizardService
        .generateTourData(
          requestDate.toISOString().substring(0, 10),
          this.startWithTour?.id
        )
        .subscribe((res) => {
          this.wizardData = res.filter((r) => {
            if (new Date(r.runtime) >= new Date()) {
              return r;
            }
          });
          if (this.wizardData && this.wizardData.length > 0) {
            const foundItemId = this.booking.hike
              ? this.wizardData.find((wd) => {
                  if (wd.id === this.booking.hike.id) {
                    return wd;
                  }
                })
              : null;
            const foundItem = this.wizardData.find((wd) => {
              if (
                wd.runtime.substring(0, 10) ===
                requestDate.toISOString().substring(0, 10)
              ) {
                return wd;
              }
            });
            this.booking.hike = foundItemId
              ? foundItemId
              : foundItem
              ? foundItem
              : null;
          }
        });
    }
  }

  /** Zum vorherigen Step */
  prevStep() {
    this.setStep(this.currentStep - 1);
  }

  /** Geht zum nächsten Schritt / Speichert */
  nextStep() {
    this.setStep(this.currentStep + 1);
  }

  /**
   * Setzt die Type Daten zurück damit nicht beides gesendet wird wenn nach eingabe gewechselt wird
   */
  resetType() {
    this.booking.family_tickets = 0;
    this.booking.family_adult_count = 0;
    this.booking.family_child_count = 0;
    this.booking.child_tickets = 0;
    this.booking.adult_tickets = 0;
  }

  /**
   * Hike auswählen
   * @param h
   */
  selectHike(h: HikeModel) {
    this.booking.hike = h;
  }

  /**
   * Berechnet die Summe und gibt sie zurück
   */
  getSum(): number {
    return Number(
      this.booking.adult_tickets * this.getPriceAdult() +
        this.booking.family_tickets * this.getPriceFamily() +
        this.booking.child_tickets * this.getPriceKid()
    );
  }

  getPriceAdult(): number {
    return Number(
      this.booking.hike.tour.price_adult +
        (this.booking.hike.sundowner
          ? this.booking.hike.tour.price_sundowner
          : 0)
    );
  }

  getPriceKid(): number {
    return Number(
      this.booking.hike.tour.price_kids +
        (this.booking.hike.sundowner
          ? this.booking.hike.tour.price_sundowner
          : 0)
    );
  }

  getPriceFamily(): number {
    return Number(
      this.booking.hike.tour.price_family +
        (this.booking.hike.sundowner
          ? this.booking.hike.tour.price_sundowner_family
          : 0)
    );
  }

  /**
   * Schließt den Wizard anhand des Events
   */
  closeWizard(): void {
    this.resetData();
    this.closingWizard.emit();
  }

  /**
   * Prüft das Datum zum disablen
   */
  private checkDate(hdt: Date, d: Date): boolean {
    const nd: Date = new Date(d.toISOString().substring(0, 10));
    const ndt: Date = new Date(d);
    const hd = new Date(hdt.toISOString().substring(0, 10));
    // hdt = Hike-DateTime / hd = Hike-Date
    const isCorrectDay: boolean = nd.getTime() === hd.getTime() - 86400000;
    const isCorrectTimeToday: boolean =
      new Date().getTime() != hd.getTime() ||
      (new Date().getTime() === hd.getTime() &&
        hdt.getHours() >= ndt.getHours() &&
        hdt.getMinutes() >= ndt.getMinutes());
    return isCorrectDay && isCorrectTimeToday;
  }
}
