<span class="background" (click)="clickedBackdrop($event)">
  <div class="modal_view" (click)="clickedModal($event)">
    <span class="modal_header">
      <span class="modal_header_text">Finden und Buchen</span>
      <span class="modal_header_close" (click)="closeWizard()">X</span>
      <div class="stepper">
        <div
          class="stepper_step"
          *ngFor="let step of steps; let i = index"
          (click)="setStep(i)"
          [ngClass]="{
            step_active: currentStep === i,
            disabled: isDisabled(i)
          }"
        >
          {{ i + 1 }}. {{ step }}
        </div>
      </div>
    </span>
    <span class="modal_body">
      <span class="datepickerWrapper" *ngIf="currentStep === 0 && allHikeDates">
        <mat-card class="inline-calendar-card">
          <mat-calendar
            [(selected)]="selectedDate"
            [minDate]="disableToDate"
            [dateFilter]="wizardDateFilter"
            (selectedChange)="changedDate()"
          >
          </mat-calendar>
        </mat-card>
      </span>
      <span class="tourList" *ngIf="currentStep === 1 && wizardData">
        <div class="noToursText" *ngIf="wizardData.length === 0">
          Leider bieten wie für diesen Zeitraum keine Touren an...
        </div>
        <div
          class="tourItem"
          *ngFor="let hike of wizardData"
          [ngClass]="{ highlight: booking.hike === hike }"
          (click)="selectHike(hike)"
        >
          <img src="{{ 'assets/images/tours/' + hike.tour?.id + '.jpg' }}" />
          <span class="infoBox">
            <span class="tourName"
              >{{ hike.tour?.name }}
              {{ hike.sundowner ? "(Sundowner)" : "" }}</span
            >
            <span class="tourInfo"
              >{{ hike.runtime | date: "dd.MM.yyyy HH:mm" }}...</span
            >
          </span>
        </div>
      </span>
      <span class="inputsWrapper" *ngIf="currentStep === 2">
        <mat-form-field appearance="fill">
          <mat-label>Einzel- / Familienticket</mat-label>
          <mat-select [(ngModel)]="booking_type" (ngModelChange)="resetType()">
            <mat-option value="single">Einzeltickets</mat-option>
            <mat-option value="family"
              >Familienticket (2 Erwachsene und max. 3 Kinder unter 14
              Jahren)</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="numberField"
          *ngIf="booking_type === 'family'"
        >
          <mat-label
            >Familienticket (2 Erwachsene und max. 3 Kinder unter 14 Jahren) je
            {{ getPriceFamily() }},00€</mat-label
          >
          <input
            matInput
            placeholder="Anzahl"
            type="number"
            [(ngModel)]="booking.family_tickets"
          />
        </mat-form-field>
        <span class="TwoColumnsGrid" *ngIf="booking_type === 'family'">
          <mat-form-field appearance="fill" class="numberField">
            <mat-label>Erwachsene</mat-label>
            <input
              matInput
              placeholder="Anzahl Erwachsene"
              type="number"
              [(ngModel)]="booking.family_adult_count"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="numberField">
            <mat-label>Kinder</mat-label>
            <input
              matInput
              placeholder="Anzahl Kinder"
              type="number"
              [(ngModel)]="booking.family_child_count"
            />
          </mat-form-field>
        </span>
        <mat-form-field
          appearance="fill"
          class="numberField"
          *ngIf="booking_type === 'single'"
        >
          <mat-label>Erwachsene je {{ getPriceAdult() }},00€</mat-label>
          <input
            matInput
            placeholder="Anzahl"
            type="number"
            [(ngModel)]="booking.adult_tickets"
          />
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="numberField"
          *ngIf="booking_type === 'single'"
        >
          <mat-label
            >Kinder (bis 14 Jahre) je {{ getPriceKid() }},00€</mat-label
          >
          <input
            matInput
            placeholder="Anzahl"
            type="number"
            [(ngModel)]="booking.child_tickets"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="numberField">
          <mat-label>Hunde je 0,00€</mat-label>
          <input
            matInput
            placeholder="Anzahl"
            type="number"
            [(ngModel)]="booking.dog_tickets"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Hotelname, falls bekannt</mat-label>
          <input
            matInput
            placeholder="Musterhotel"
            [(ngModel)]="booking.hotel_name"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Vor- / Nachname</mat-label>
          <input
            matInput
            placeholder="Max Mustermann"
            [(ngModel)]="booking.name"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Adresse / Hausnr.</mat-label>
          <input
            matInput
            placeholder="Musterstraße 1"
            [(ngModel)]="booking.street_and_number"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>PLZ / Ort</mat-label>
          <input
            matInput
            placeholder="01234 Musterstadt"
            [(ngModel)]="booking.zip_and_city"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="numberField">
          <mat-label>Mobilnummer</mat-label>
          <input
            matInput
            placeholder="0123 45678911"
            type="tel"
            [(ngModel)]="booking.tel_number"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>E-Mail</mat-label>
          <input
            matInput
            placeholder="muster@email.de"
            type="mail"
            [(ngModel)]="booking.mail"
          />
        </mat-form-field>
      </span>
      <span class="overviewWrapper" *ngIf="currentStep === 3">
        <span class="textWrapper" *ngIf="booking.hike?.tour?.name">
          <span class="textOver">Tour: </span>
          <span class="textData">{{ booking.hike.tour.name }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.hike?.runtime">
          <span class="textOver">Datum / Uhrzeit: </span>
          <span class="textData">{{
            booking.hike?.runtime | date: "dd.MM.yyyy HH:mm"
          }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.family_tickets > 0">
          <span class="textOver">Familien-Tickets: </span>
          <span class="textData">{{ booking.family_tickets }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.adult_tickets > 0">
          <span class="textOver">Erwachsenen-Tickets: </span>
          <span class="textData">{{ booking.adult_tickets }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.child_tickets > 0">
          <span class="textOver">Kinder-Tickets: </span>
          <span class="textData">{{ booking.child_tickets }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.dog_tickets > 0">
          <span class="textOver">Hunde-Tickets: </span>
          <span class="textData">{{ booking.dog_tickets }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.hotel_name">
          <span class="textOver">Hotelname: </span>
          <span class="textData">{{ booking.hotel_name }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.name">
          <span class="textOver">Vor- / Nachname: </span>
          <span class="textData">{{ booking.name }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.street_and_number">
          <span class="textOver">Adresse / Hausnr.: </span>
          <span class="textData">{{ booking.street_and_number }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.zip_and_city">
          <span class="textOver">ZIP / Ort: </span>
          <span class="textData">{{ booking.zip_and_city }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.tel_number">
          <span class="textOver">Mobilnummer: </span>
          <span class="textData">{{ booking.tel_number }}</span>
        </span>
        <span class="textWrapper" *ngIf="booking.mail">
          <span class="textOver">E-Mail: </span>
          <span class="textData">{{ booking.mail }}</span>
        </span>
        <span class="checkboxesOverText"
          >Allgemeine Geschäftsbedingungen | Wiederrufsbelehrung |
          Datenschutzerklärung *</span
        >
        <mat-checkbox [(ngModel)]="accepted_first"
          >Mit absenden der Buchungsanfrage akzeptieren Sie unsere Allgemeinen
          Geschäftsbedingungen sowie die Widerrufsbelehrung.</mat-checkbox
        >
        <mat-checkbox [(ngModel)]="accepted_second"
          >Ich habe die Datenschutzerklärung gelesen und akzeptiert.
        </mat-checkbox>
      </span>
    </span>

    <span class="modal_footer">
      <span class="sumInvoiceText">
        <span *ngIf="currentStep >= 2"
          >Zu zahlender Betrag: <b>{{ getSum() | number: "0.2-2" }}€</b>
        </span>
      </span>
      <span class="footer_buttons">
        <div
          class="footer_button"
          (click)="prevStep()"
          [ngClass]="{ disabled: isDisabled(currentStep - 1) }"
        >
          <span>Zurück</span>
        </div>
        <div
          class="footer_button highlight"
          (click)="nextStep()"
          [ngClass]="{ disabled: isDisabled(currentStep + 1) }"
        >
          <span *ngIf="currentStep !== steps.length - 1">Weiter</span>
          <span *ngIf="currentStep === steps.length - 1"
            >Kostenpflichtig Buchen</span
          >
        </div>
      </span>
    </span>
  </div>
</span>
