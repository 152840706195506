import { BookingModel } from './../../models/booking';
import { Injectable } from '@angular/core';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends DataService<BookingModel> {
  endpoint = 'bookings';
  objectName = 'booking';
}
