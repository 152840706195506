import { PartnerService } from './../../provider/model-services/partner-service';
import { PartnerModel } from './../../models/partner';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/provider/data-service';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  partners: PartnerModel[] = [];

  get url(): string {
    return DataService.serviceUrl;
  }

  constructor(private partnerService: PartnerService) {}

  ngOnInit(): void {
    this.getAllPartner();
  }

  private getAllPartner(): void {
    this.partnerService.all().subscribe((res) => {
      this.partners = res;
    });
  }
}
