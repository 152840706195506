import { Injectable } from "@angular/core";
import { DataprivacyModel } from "src/app/models/dataprivacy";
import { DataService } from "../data-service";

@Injectable({
  providedIn: "root",
})
export class DataprivacyService extends DataService<DataprivacyModel> {
  endpoint = "dataprivacies";
  objectName = "dataprivacy";
}
