import { Injectable } from '@angular/core';
import { ContactModel } from 'src/app/models/contact';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends DataService<ContactModel> {
  endpoint = 'contacts';
  objectName = 'contact';
}
