import { Injectable } from '@angular/core';
import { TourModel } from 'src/app/models/tour';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class TourService extends DataService<TourModel> {
  endpoint = 'tours';
  objectName = 'tour';
}
