import { Injectable } from '@angular/core';
import { PartnerModel } from 'src/app/models/partner';
import { DataService } from '../data-service';

@Injectable({
  providedIn: 'root',
})
export class PartnerService extends DataService<PartnerModel> {
  endpoint = 'partners';
  objectName = 'partner';
}
