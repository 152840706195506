import { CouponService } from "./../../provider/model-services/coupon-service";
import { CouponModel } from "./../../models/coupon";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-gutschein",
  templateUrl: "./gutschein.component.html",
  styleUrls: ["./gutschein.component.scss"],
})
export class GutscheinComponent {
  coupon: CouponModel;
  accepted_first: boolean = false;
  accepted_second: boolean = false;

  constructor(
    private couponService: CouponService,
    private snackBar: MatSnackBar
  ) {
    this.coupon = {
      id: null,
      created_at: null,
      updated_at: null,
      value: 20,
      firstname: "",
      lastname: "",
      street: "",
      zip: "",
      city: "",
      mobile_phone: "",
      email: "",
      price: 35.5,
    };
  }

  isValid() {
    return (
      this.accepted_first &&
      this.accepted_second &&
      this.coupon.lastname &&
      this.coupon.firstname &&
      this.coupon.value >= 20 &&
      this.coupon.street &&
      this.coupon.zip &&
      this.coupon.city &&
      this.coupon.email &&
      this.coupon.mobile_phone
    );
  }

  changedValue() {
    if (this.coupon.value < 20) {
      this.coupon.value = 20;
    }
    this.coupon.price = this.coupon.value + 15.5;
  }

  buyCoupon() {
    if (this.isValid()) {
      this.couponService.create(this.coupon).subscribe((res) => {
        this.snackBar.open("Ihre Buchung war erfolgreich!", "Schließen");
        this.coupon = {
          id: null,
          created_at: null,
          updated_at: null,
          value: 20,
          firstname: "",
          lastname: "",
          street: "",
          zip: "",
          city: "",
          mobile_phone: "",
          email: "",
          price: 35.5,
        };
      });
    }
  }
}
