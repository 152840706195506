import { TourModel } from "src/app/models/tour";
import { HikeModel } from "src/app/models/hike";
export class GlobalDataService {
  wizardData: {
    hike: HikeModel;
    tour: TourModel;
    openWizard: boolean;
  } = {
    hike: null,
    tour: null,
    openWizard: false,
  };
}
